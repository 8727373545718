
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        





















































































































































































































.heroooo {
  position: relative;
  z-index: 10;
  margin-top: 3rem;
  color: #fff;
  font-family: $ff-alt;
}

.hero__top {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mq(l) {
    flex-flow: row wrap;
    overflow: hidden;
    margin-top: 6rem;
  }

  @include mq($until: l) {
    .hero__infos,
    .hero__card {
      order: 1;
    }

    .hero__infos {
      margin-bottom: 1.5rem;
    }

    .hero__card {
      margin-top: 0;
      margin-bottom: 2.3rem;
    }

    .hero__cta {
      order: 2;
    }
  }
}

.hero__infos {
  @include mq(l) {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-end;

    &::before {
      display: none;
    }
  }
}

.hero__label {
  @extend %fw-normal;

  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 5px;

  @include mq($until: l) {
    padding: 4px 7px;
  }

  @include mq(l) {
    font-size: 18px;
  }
}

.hero__title {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 0;

  @include mq(l) {
    margin-top: 3rem;
  }
}

::v-deep .subtitles-pictograms {
  @include mq($until: m) {
    justify-content: flex-start;
    width: 70%;
  }

  @include mq(m) {
    margin-bottom: 0;
  }
}

.hero__list {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  height: fit-content;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  padding: 0;
  list-style: none;
  gap: 1.4rem;
  color: rgba($white, 0.7);
  font-size: 9px;
  text-transform: uppercase;
  letter-spacing: 0.6px;

  li {
    @include mq(l, xl) {
      line-height: 1;
    }
  }

  @include mq($until: l) {
    row-gap: 0;
  }

  @include mq(l) {
    width: 50%;
    margin-bottom: 0;
    font-size: 17px;
  }

  @include mq(xl) {
    width: 60%;
  }
}

.hero__list-link {
  @include mq($until: l) {
    width: auto;
  }
}

.hero__client {
  gap: 16px;

  span {
    font-size: 18px;
  }

  a {
    ::v-deep .btn__icon {
      display: none;
    }
  }

  @include mq($until: l) {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 24px 0;
    padding: 24px 0;
    gap: 13px;

    &::before {
      @include get-all-space;

      z-index: -1;
      left: -2rem;
      display: block;
      width: calc(100% + 4rem);
      content: '';
      background: rgba($white, 0.03);
      box-shadow: 0 0 15px rgba($black, 0.2);
    }
  }
}

.hero__cta {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;

  span {
    @include mq($until: l) {
      font-size: 12px;
    }
  }

  div {
    display: flex;
    flex-direction: column;

    @include mq(l) {
      align-items: center;
    }
  }

  @include mq($until: l) {
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2.7rem;
    padding: 2.4rem 0;

    &::before {
      @include get-all-space;

      left: -50%;
      display: block;
      width: 200%;
      content: '';
      background: rgba($white, 0.03);
      box-shadow: 0 0 15px rgba($black, 0.02);
    }
  }

  @include mq(l) {
    width: 50%;
    margin-top: 8rem;
    gap: 3rem;
  }

  @include mq(xl) {
    width: 40%;
  }
}

.hero__cta__picture {
  display: flex;
  max-width: 68px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;

  @include mq(l) {
    max-width: 107px;
  }
}

.hero__card {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-top: 4rem;
  border-radius: 10px;
  aspect-ratio: 16 / 9;

  .hero__card__cover {
    object-fit: cover;
    object-position: top;
    width: 100%;
    height: 100%;
  }
}

.hero__card__popup {
  @include get-all-space;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;

  .icon {
    width: 56px;
    aspect-ratio: 1 / 1;
  }
}

.hero__bottom__video__popup,
.hero__card {
  &::before {
    @include get-all-space;

    // prettier-ignore
    background: linear-gradient(180deg, rgba($black, 0) 0%, rgba($black, 0.9) 100%);
    content: '';
  }
}

.hero__bottom {
  span {
    @extend %fw-semi;

    display: block;
    margin-bottom: 10px;
    font-size: 15px;

    @include mq($until: l) {
      margin-top: 24px;
    }

    @include mq(l) {
      font-size: 24px;
    }
  }

  p {
    margin-top: 0;
  }

  @include mq(l) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 82px 106px;
    background: rgba($white, 0.02);
    border-radius: 0 0 10px 10px;
    box-shadow: 0 0 15px rgba($black, 0.1);
  }
}

.hero__bottom__left {
  @include mq(l) {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    width: 65%;
  }
}

.left__bottom {
  @include mq(l) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .left__bottom__casting {
      width: 70%;
    }
  }
}

.hero__bottom__right {
  width: 30%;
}

.video__popup__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  transition: all 0.2s ease-in-out;
}

.hero__bottom__video__popup {
  position: relative;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 16 / 9;
  cursor: pointer;
  border-radius: 10px;

  .video__popup__img:hover {
    transform: scale(1.05);
  }
}

.video__popup__icon {
  position: absolute;
  z-index: 10;
  pointer-events: none;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  transform: translate(-50%, -50%);
}

.hero__offer-price {
  @include mq($until: l) {
    /* stylelint-disable-next-line declaration-no-important */
    align-items: flex-start !important;
  }
}

.hero__offer-price__title {
  font-size: 24px;
  font-weight: 600;
}

::v-deep .hero__cta .hero__client__btn span {
  @include mq(l) {
    font-size: 2.4rem;
  }
}
