
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        






































::v-deep .tv-rack__title {
  margin-top: 0;
}

::v-deep .robbie-tv-title {
  @include mq(l) {
    width: 50%;
  }
}

.rack {
  @include mq($until: l) {
    margin-bottom: 0;
    padding-bottom: 7rem;
  }

  @include mq(l) {
    background: rgba($white, 0.02);
    box-shadow: 0 0 35px rgba($black, 0.1);
  }
}
